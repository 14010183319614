.custom-modal-header {
    padding: 2.375rem 2.375rem 0 2.375rem; 
    border-bottom: none;
}

.custom-modal-header .modal-title {
    color: #212529; 
    text-align: left;
    font-family: 'Inter', sans-serif;
    font-size: 1.375rem; 
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 100%; 
    margin-bottom: 0.625rem;
}

.custom-modal-body .modal-body {
    padding: 0 2.375rem !important;
}

.custom-modal-body p {
    color: rgba(33, 37, 41, 0.75);
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 3.125rem;
}

.modal-body .profile-image-container {
    margin-bottom: 3.125rem !important; 
}

.profile-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e0e0e0;
    width: 100%;
    max-width: 9.938rem;
    height: auto;
    aspect-ratio: 1/1; 
    border-radius: 50%;
    margin: auto;
    margin-bottom: 3.125rem;
  }
  
  .profile-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 50%;
  }
  

.custom-label {
    color: var(--Dark-Gray, #212529);
    font-family: 'Inter', sans-serif;
    font-size: 0.875rem !important;
    font-style: normal;
    font-weight: 600;
    line-height: 1.358rem !important;
}

.custom-form-control.form-control {
    width: 100%;
    max-width: 100%;
    padding: 0.5rem 0.75rem !important;
    font-family: 'Inter', sans-serif !important;
    font-size: 0.875rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    color: #495057 !important;
    background-color: #f7f7f7;
    background-clip: padding-box !important;
    border: 1px solid #ced4da !important;
    border-radius: 0.375rem !important;
    box-shadow: 0px 0.0625rem 0.125rem 0px rgba(0, 0, 0, 0.08) !important; 
}

.custom-form-control.form-control:focus {
    color: #495057 !important;
    background-color: #f7f7f7 !important;
    border-color: #7a7a7a !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.2rem rgba(131, 131, 131, 0.25) !important;
}

.custom-form-control.form-control::placeholder {
    color: #6c757d !important;
    opacity: 1 !important;
}

.custom-button {
    color: #212529 !important;
    text-align: center;
    font-family: 'Inter', sans-serif !important;
    font-size: 1.125rem !important; 
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 1.875rem !important; 
    letter-spacing: 0.36px !important;
}

.custom-secondary-button {
    border-radius: 0.375rem !important;
    background: #C4C4C4 !important;
    box-shadow: 0px 0.0625rem 0px 0px rgba(255, 255, 255, 0.15) !important;
    border: none !important;
}