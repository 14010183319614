@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');


.profile-name{
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 30px;
    margin: 10px 0;
}

.profile-email{
    color: var(--Dark-Gray, #212529);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    }

.profile-info{
    color: var(--Dark-Gray, #212529);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.edit-profile-button {
    background-color: #6c757d;
    color: white;
    border: 1px solid #6c757d;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
    transition: background-color 0.3s, color 0.3s;
  }

  .edit-profile-button:hover {
    background-color: #5a6268;
    color: white;
    border-color: #545b62;
  }