.primary-button {
    display: inline-flex;
    padding: 7px 14px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: auto; 
    height: auto;
    border: none;
    border-radius: 6px;
    background: #FFD700;
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.15);
    color: #212529;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 187.5% */
    letter-spacing: 0.272px;
    text-transform: uppercase;
  }

  .primary-button--variant2 {
    background: #2b2b2b;
    color: #F8F8F8;
  }