.info-bg {
    background: #F5F5F5;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}

.text-justify {
    text-align: justify !important;
}

.info-bg h1 {
    color: #212529;
    text-align: center;
    font-family: Lora;
    font-size: 2rem; 
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: 1.68px;
    margin-bottom: 20px;
    margin-top: 3rem;
}

.info-bg p {
    color: rgba(33, 37, 41, 0.75);
    text-align: left;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 1.5px;
    font-weight: 500;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
}

.info-bg h2 {
    color: #212529;
    text-align: left;
    font-family: Lora;
    font-size: 2rem; 
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: 1.68px;
    margin-bottom: 20px;
    margin-top: 1.5rem;
}

.custom-bullet {
    color: rgba(33, 37, 41, 0.75);
    text-align: left;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 1.5px;
}

/* Responsive adjustments */
@media (min-width: 576px) {
    .info-bg h1 {
        font-size: 3%;
    }
    .info-bg h2 {
        font-size: 2.5rem;
    }
}

@media (min-width: 768px) {
    .info-bg h1 {
        font-size: 3.5rem;
    }
    .info-bg h2 {
        font-size: 3rem;
    }
}

@media (min-width: 992px) {
    .info-bg h1 {
        font-size: 4rem;
    }
    .info-bg h2 {
        font-size: 3.5rem;
    }
}

@media (min-width: 1200px) {
    .info-bg h1 {
        font-size: 4.5rem;
    }
    .info-bg h2 {
        font-size: 4rem;
    }
}
