@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

/*Sidebar with icon only*/
.icon-sidebar {
  background-image: url('../../images/new_bg_6-transformed.png');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat; 
  width: 60px; /* Adjust the width as needed */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 0;
  position: sticky;
  top: 0;
  height: 100vh;
}

.icon-sidebar .nav-link {
  color: #ffffff;
  font-size: 18px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-sidebar .nav-link i {
  font-size: 24px;
}

.icon-sidebar .nav-link:hover{
  text-decoration: none;
  color: #ffffff; 
  transform: scale(1.2); /*zoom effect*/
  transition: transform 0.3s ease;/* Add transition for smooth effect */
}

.navbar-toggler i {
  color: #ffffff;
  margin-bottom: 30px; 
}

/* Custom Offcanvas styling */
.custom-offcanvas {
  background-image: url('../../images/new_bg_6-transformed.png');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat; 
  color: #ffffff;
}
.custom-offcanvas .offcanvas-header {
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
}
.btn-close {
  color: #fff !important; 
}
.custom-offcanvas .nav-link i {
  font-size: 25px;
  margin-right: 10px; /* adds space between icon and text */
}
.custom-offcanvas .nav-link {
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  padding: 10px 0;
  display: flex;
  align-items: center; 
  transition: transform 0.3s ease; 
}
.custom-offcanvas .nav-link:hover {
  text-decoration: none;
  color: #ffffff; 
  transform: scale(1.2); 
  transition: transform 0.3s ease;
}

.btn-light.signinout-navbar-button{
  color: #212529 !important;
    text-align: center;
    font-family: 'Inter', sans-serif !important;
    font-size: 1.125 !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 30px !important; 
    letter-spacing: 0.36px !important;
    padding: 2px 10px; 
    width:auto;
    height: auto;
}


/*Sidebar in large screen*/
.sidebar {
  background-image: url('../../images/new_bg_6-transformed.png');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat; 
  width: 200px;
  padding: 30px 20px 70px 20px;
}
.flex-column {
  align-items: flex-start;
  font-family: 'Inter', sans-serif;
}
.sidebar .nav-link {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  padding: 10px 0;
  display: flex;
  align-items: center; 
  transition: transform 0.3s ease;
}

.sidebar .nav-link i {
  font-size: 24px;
  margin-right: 10px; /*adds space between icon and text*/
}
.sidebar .nav-link:hover {
  text-decoration: none;
  text-decoration: none;
  transform: scale(1.2); 
}
.sidebar .menu-title{
  margin-bottom: 25px;
}

.sidebar .menu-title h2{
  align-items: flex-start;
  font-family: 'Inter', sans-serif;
  font-size: 26px;
  font-weight: 500;
}

.navbar-toggler {
  border: none;
  background: none;
}

.navbar-toggler {
  border: none !important;
  background: none;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: none;
  box-shadow: none;
}

.navbar-toggler i {
  font-size: 24px; /* Adjust icon size */
}