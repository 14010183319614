/* .custom-image-effect {
    transition: transform 0.3s ease; 
  }
  
  .custom-image-effect:hover {
    transform: scale(1.1); 
  } */

  .custom-image-effect {
    transition: opacity 0.2s ease;
  }

  .custom-image-effect:hover {
    opacity: 0.7;
    background-color: rgba(148, 148, 148, 0.5);
  }

  /* .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .card-body {
    flex-grow: 1;
  }
  
  .card-footer {
    display: flex;
    justify-content: flex-end;
    background: none !important;
    border-top: none !important;
  } */

  .custom-more-button {
    width: 80px !important;
    padding: 5px !important;
    border-radius: 6px !important;
    background: none !important;
    box-shadow: none !important;
    font-family: Inter !important;
    font-size: 0.9rem !important;
    color: black !important;
    letter-spacing: 1.5px !important;
    font-weight: 600 !important;
    border: none !important;
  }
  
  .custom-more-button:hover {
    background: none !important;
    box-shadow: none !important;
    color: #292727 !important;
    text-decoration: underline !important;
  }

  .card-body.data{
    font-family: 'Lora';
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    cursor: pointer;
    user-select: none; /* Standard CSS property */
    -webkit-user-select: none; /* For Safari */
    -moz-user-select: none; /* For Firefox */
    -ms-user-select: none; /* For IE and Edge */
  }
  
  .load-more-button {
    background-color: #007bff;
    color: white;
    border: none;
    /* padding: 10px 20px; */
    border-radius: 5px;
    cursor: pointer;
  }
  
  .load-more-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .wireframe-container {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }
  
  .wireframe-container.loaded {
    opacity: 0;
  }
  

  