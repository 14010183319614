.ant-drawer {
  z-index: 1000;
}
.client-entry-body {
  padding:  0 4vw 0 4vw;
  background-image: url('../../images/new_bg_3-transformed.png');
  background-size: cover; /* Adjusts the image to cover the entire background */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
}
.custom-height {
    min-height: 500px; 
}
  .custom-h1 {
    color: #f8f8f8;
    font-family: 'Lora', serif;
    font-size: 3.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 50px; /* 89.286% */
  }
  .custom-p {
    color: rgba(200, 206, 211, 0.75);
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .btn-light.new-button {
    color: #212529 !important;
    text-align: center;
    font-family: 'Inter', sans-serif !important;
    font-size: 1.125 !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 30px !important; 
    letter-spacing: 0.36px !important;
    width: 150px;
  }
  .custom-first-p {
    color: #f8f8f8;
    font-family: 'Inter', sans-serif;
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
  }
  .custom-h2 {
    color: #f8f8f8;
    font-family: 'Lora', serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 35px; /* 87.5% */
  }

  .custom-second-p{
    color: rgba(200, 206, 211, 0.75);
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .custom-h3 {
    color: #f8f8f8;
    font-family: Inter;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .custom-third-p{
    color: rgba(200, 206, 211, 0.75);
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .custom-3rd-h2{
    color: #f8f8f8;
    font-family: Lora;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 50px; 
  }
  .custom-3rd-p{
    color: rgba(200, 206, 211, 0.75);
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .custom-text-left {
    text-align: left !important;
  }
  .custom-3rd-card {
    color: black !important;
    font-family: Inter !important;
    font-size: 1.375rem !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal !important;
  }
  .list-unstyled{
    color: black;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  }
  .list-unstyled li{
    margin-bottom: 10px;
  }
button.learn-more {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: 1rem;
  font-family: 'Inter';
  width: 20rem;
  height: auto;
}
button.learn-more .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: #FFD700;
  border-radius: 1.625rem;
}
button.learn-more .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: none;
}
button.learn-more .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}
button.learn-more .circle .icon.arrow::before {
  position: absolute;
  content: '';
  top: -0.25rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid black;
  border-right: 0.125rem solid black;
  transform: rotate(45deg);
}
button.learn-more .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0 0 1rem;
  margin: 0 0 0 1.85rem;
  color: #f8f8f8;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
}
button.learn-more:hover .circle {
  width: 100%;
}
button.learn-more:hover .circle .icon.arrow {
  background: black;
  transform: translate(1rem, 0);
}
button.learn-more:hover .button-text {
  color: black;
}
@media (max-width: 768px) {
  .client-entry-body {
    padding: 0 2vw 0 2vw;
  }
}