@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Lora:wght@700&display=swap');

.search-container {
  padding: 20px;
  margin-bottom: 20px;
}
.search-button{
  color: #212529 !important;
  text-align: center;
  font-family: 'Inter', sans-serif !important;
  font-size: 1.125 !important;
  font-style: normal !important;
  font-weight: 650 !important;
  line-height: 30px !important; 
  letter-spacing: 0.36px !important;
  padding: 2px 10px; 
  border-radius: 6px;
  background: #C4C4C4;
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.15);
  margin-left: 6px;
  border: none;
  width: auto; 
  height: auto; 
}

.search-button:hover{
  background-color:#cacaca !important;
}

.search-bar { 
  flex-grow: 1; 
  border: 1px solid #CED4DA; 
  border-radius: 4px;
}
.search-bar:focus {
  border-color: #6C757D !important; 
  box-shadow: 1px 1px 10px rgba(125, 109, 108, 0.5) !important;
  outline: none;
}

.custom-dropdown .dropdown-toggle {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
  background-color: #FFFFFF;
  border: 1px solid #CED4DA;
  border-radius: 4px;
  color: #495057;
}

.custom-dropdown .dropdown-toggle:hover,
.custom-dropdown .dropdown-toggle:focus,
.custom-dropdown .dropdown-toggle:active,
.custom-dropdown .dropdown-toggle.show {
  background-color: #6C757D; 
  color: #FFFFFF; 
  border: none;
  box-shadow: none !important; 
  outline: none !important; 
}
.custom-dropdown .dropdown-toggle:active:focus {
  background-color: #555555 !important;
  color: #FFFFFF !important;
  box-shadow: none !important;
  outline: none !important;
}

.custom-dropdown .dropdown-toggle::after {
  margin-left: 10px;
}

.custom-dropdown .dropdown-menu {
  border: 1px solid #CED4DA;
  border-radius: 4px;
  box-shadow: none;
}

.custom-dropdown .dropdown-item {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  color: #495057;
}

.custom-dropdown .dropdown-item:hover {
  background-color: #f1f1f1; 
}

.form-control {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
}