.site-footer {
  flex-shrink: 0;
}

.footer-link {
  color: white;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
  color: #61dafb;
}

@media (max-width: 767px) {
  .footer-link {
    display: inline-block;
    margin: 0.25rem 0.5rem;
  }
}