.follow-us-component {
    /* position: absolute; */
    /* top: 50%; Center vertically */
    /* transform: translateY(-50%); */
    /* right: 20px; Adjust distance from the right edge */
    width: 260px;
    height: 230px;
    padding: 10px;
    display: flex;
    justify-content: flex-end; /* Right justify the content inside */
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    border-radius: 6px;
    border: 1px solid #DEE2E6;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
}
.follow-us-component .content {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.follow-us-component .follow-us {
    text-align: center;
}
.follow-us-component h2 {
    font-weight: bold;
}
.follow-us-component .paragraph {
    color: var(--Dark-Gray, #212529);
    width: 239px;
    flex-shrink: 0;
    /* Body 2 */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.follow-us-component .social-icons {
    display: flex;
    justify-content: center;
    gap: 10px;
}
.follow-us-component .social-icons img {
    width: 24px;
    height: 24px;
    cursor: pointer;
}