@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

.landing-nav {
  background: rgba(20, 25, 33, 0.00); /* Adjust the color and transparency */
  backdrop-filter: blur(10px); /* Adjust the blur intensity */
  -webkit-backdrop-filter: blur(10px); /* For Safari */
  z-index: 999;
}
.landing-nav *{
  box-sizing: border-box;
  transition: all .35s ease;
}
.flex-column {
  align-items: flex-start;
  font-family: 'Inter', sans-serif;
}
.landing-nav .nav-link {
  font-size: 20px;
  font-weight: 500;
  color: rgb(233, 225, 225);
  padding: .5em .8em;
  position: relative;
  text-decoration: none;
}
.ladning-nav .nav-link::before,
.landing-nav .nav-link::after {
  content: '';
  height: 14px;
  width: 14px;
  position: absolute;
  transition: all .35s ease;
  opacity: 0;
}

.ladning-nav .nav-link::before {
  content: '';
  right: 0;
  top: 0;
  border-top: 3px solid #FECB3E;
  border-right: 3px solid #FECB3E;
  transform: translate(-100%, 50%);
}

.landing-nav .nav-link:after {
  content: '';
  left: 0;
  bottom: 0;
  border-bottom: 3px solid #FECB3E;
  border-left: 3px solid #FECB3E;
  transform: translate(100%, -50%)
}

.landing-nav .nav-link:hover:before,
.landing-nav .nav-link:hover:after{
  transform: translate(0,0);
  opacity: 1;
}

.landing-nav .nav-link:hover {
  color: #FECB3E;
}
.custom-offcanvas-landing {
  background-image: url('../../images/new_bg_6-transformed.png');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat; 
}
.custom-offcanvas-landing .nav-link {
  font-size: 18px;
}
.custom-offcanvas-landing .nav-link:hover {
  background-color: #495057 !important;
  text-decoration: none !important;
}
.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
.custom-toggler.navbar-toggler {
  border-color: rgb(255,255,255);
}