.media-container {
  position: relative;
  display: flex;
  justify-content: center;
  max-width: 100%;
  overflow: hidden;
}

.video-wrapper, .image-wrapper {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: auto;
}

.custom-video, .custom-img {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
}

.custom-watermark, .watermark {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.4;
  pointer-events: none;
  z-index: 100;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.overlay-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.overlay-content.visible {
  opacity: 1;
  visibility: visible;
}

.central-media {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 30vh;
  border-radius: 5px;
  object-fit: contain;
  transition: all 0.3s ease;
  cursor: pointer;
  transition: all 0.3s ease;
}

.central-media.expanded {
  position: fixed;
  max-width: 90vw;
  max-height: 90vh;
  z-index: 501;
}

.media-transition-enter {
  opacity: 0;
  transform: scale(0.9);
}

.media-transition-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.media-transition-exit {
  opacity: 1;
  transform: scale(1);
}

.media-transition-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.overlay-card {
  background-color: #1C1C1C !important;
  border-color: #4B4C4F;
  color: #fff !important;
}

.overlay-card .card-header {
  background-color: #4B4C4F;
  color: #fff;
  font-weight: bold;
}

@media (max-width: 768px) {
  .overlay {
    align-items: flex-start;
  }
}

.download-button {
  color: #212529;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  font-weight: 650;
  line-height: 30px;
  letter-spacing: 0.36px;
  padding: 2px 10px;
  border-radius: 6px;
  background: #C4C4C4;
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.15);
  border: none;
  width: auto;
  height: auto;
}

.download-button:hover {
  background-color: #cacaca;
}

.search-field {
  flex-grow: 1;
  border: 1px solid #CED4DA;
  border-radius: 4px;
}

.search-field:focus {
  border-color: #6C757D;
  box-shadow: 1px 1px 10px rgba(108, 117, 125, 0.5);
  outline: none;
}

@media (max-width: 767px) {
  .custom-video, .custom-img {
    max-height: 50vh;
  }
}