.loader-container {
  position: fixed;
  left: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Full viewport width */
  height: 100%; /* Full viewport height */
  background: rgba(
    255,
    255,
    255,
    0.8
  ); /* Optional: white background with opacity */
  z-index: 1000; /* Ensures it is above all other content */
}

.sign-button:hover {
  background: #5A6268; 
}

.sign-button {
  color: #212529 !important;
  text-align: center;
  font-family: 'Inter', sans-serif !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 30px !important; 
  letter-spacing: 0.36px !important;
  padding: 2px 10px; 
  border-radius: 6px;
  background: #C4C4C4;
  box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.15);
  border: none;
  width: auto; 
  height: auto; 
}

.sing-button:focus{
  outline:none;
  background: #212529 !important;
}

.sign-button:hover {
  color: #ffffff !important;
  background-color:#6d6d6d !important;
}